import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Helmet from "react-helmet";
import { Quotes } from "../components/quotes";
import { TableOfContents } from "../components/table-of-contents";
import { publishAnalyticsEvent } from "../lib/analytics";
import { styles } from "./_index.css";

const gtagScript = `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied", 
        ad_personalization: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "granted",
        wait_for_update: 2000,
    });
    gtag("set", "ads_data_redaction", true);
    gtag("set", "url_passthrough", true);
`;
// markup
const IndexPage = () => {
  return (
    <>
      <Helmet>
        <script children={gtagScript} />
        <script
          id="cookieyes"
          type="text/javascript"
          src="https://cdn-cookieyes.com/client_data/cc7e8bedad2db5b9627d4dd1/script.js"
        />
        <title>Glimpses of God and Reality</title>
        <meta
          name="description"
          content={
            "Glimpses is about gaining self-knowledge and remaining empowered. Self-knowledge comes from knowing what increases or decreases your inner peace."
          }
        />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.imageWithIconsWrapper}>
          <div className={styles.imageWithIcons}>
            <StaticImage
              className={styles.image}
              src={"../images/Glimpses Cover Spined.png"}
              alt={"Ebook cover image"}
            />
            <div className={styles.downloadContainer}>
              <p>
                Download this ebook free as an{" "}
                <a
                  href={"/Glimpses v.20241122.epub"}
                  className={styles.downloadLink}
                  onClick={() => {
                    publishAnalyticsEvent({
                      name: "ePub Downloads",
                      properties: {
                        downloads: 1,
                      },
                    });
                  }}
                >
                  ePub
                </a>{" "}
                or an{" "}
                <a
                  href={
                    "https://books.apple.com/us/book/glimpses-of-god-and-reality/id1602998965"
                  }
                  className={styles.downloadLink}
                  onClick={() => {
                    publishAnalyticsEvent({
                      name: "Apple Books Clicks",
                      properties: {
                        downloads: 1,
                      },
                    });
                  }}
                >
                  Apple Book
                </a>{" "}
                (100 MB), or as a free{" "}
                <a
                  href={"/Glimpses Book PDF v.20241122.pdf"}
                  className={styles.downloadLink}
                  onClick={() => {
                    publishAnalyticsEvent({
                      name: "PDF Downloads",
                      properties: {
                        downloads: 1,
                      },
                    });
                  }}
                >
                  PDF
                </a>{" "}
                (5 MB)
              </p>
            </div>
          </div>
        </div>
        <div className={styles.textContainer}>
          <div className={styles.text}>
            <p>
              <i>Glimpses of God and Reality (Glimpses)</i> is about how to
              integrate psychedelic experiences of sacredness. <i>Glimpses</i>{" "}
              was put together by Inner Peace Fellowship (IPF). IPF is a group
              that took psychedelics in the 1960s and since then has been
              integrating those experiences into our everyday life. We studied
              eastern religions and listened to holy men. Some of us lived with
              saints while others spent considerable time alone in high, remote
              mountains. We met in the 1970s when we came together to form a
              community of several thousand to explore reality through
              meditation. Many of us have been practicing nondirective mantra
              meditation now for over 40 years. Some of us have had careers and
              raised families while meditating two to four hours every day while
              others have lived in reclusion and meditate 6 hours every day. We
              are friends and neighbors who have been sharing one another's
              successes and mistakes for a lifetime, and our community continues
              to experiment, to learn and to thrive. This book is about what we
              learned and the mistakes we made.
            </p>

            <p>
              <i>Glimpses of God and Reality</i> is about how to increase inner
              peace, goodness and sacredness. It is about how to have a direct,
              personal relationship with whatever created life through your
              experience; not through beliefs, middlemen or organizations. A
              million new people each year (2021) use our web site{" "}
              <a href={"https://www.innerpeacefellowship.org"}>
                InnerPeaceFellowship.org
              </a>{" "}
              in 236 countries and jurisdictions.
            </p>
            <p>
              We are made from the dust of stars that exploded long ago. Somehow
              that stardust came alive 3.8 billion years ago and no one knows
              what made that happen. The view in <i>Glimpses</i> is that
              whatever created life cannot be known through words, ideas or
              beliefs, but rather that whatever created life is known through
              your experience. Further, the view in <i>Glimpses</i> is that our
              experience of being alive is the direct experience of our Creator.
              And finally, the view in <i>Glimpses</i> is that insisting your
              beliefs about our Creator are the truth – insisting that your
              beliefs somehow actually own our Creator – is idolatry that is
              tearing our world apart.
            </p>

            <p>
              <i>Glimpses</i> describes our relationship with our Creator in
              terms of science. In terms of science, the story of how Earth was
              created and how life arose on Earth is much more astonishing and
              awe inspiring than any story told by religions. <i>Glimpses</i>{" "}
              was inspired by the work of Richard Feynman, David Christian and
              Edward O. Wilson who said:
              <i>
                “Altogether, the results of the neuroscience of religion thus
                far suggest strongly that a religious instinct does indeed exist
                … [but] … surely there exist ways to find spiritual fulfillment
                without surrender and enslavement.”
              </i>{" "}
              <i>Glimpses</i> expands on Wilson’s view that humans came to
              dominate Earth through cooperation and goodness.&nbsp;
              <i>Glimpses</i> describes how to connect with your instincts for
              cooperation and goodness, and then use those instincts to create
              balance in your life and in your society.
            </p>

            <p>
              <i>Glimpses</i> is about gaining self-knowledge and remaining
              empowered. Self-knowledge comes from the fact that you know better
              than anyone what increases or decreases your inner peace, what is
              good and what is bad, and what brings you closer to sacredness;
              that you know better than anyone what works best for you. You gain
              self-knowledge by learning to trust yourself, your experiences and
              your instincts. And the self-knowledge gained is what then enables
              you to retain your power and not become disempowered by others.
            </p>

            <p>
              <i>Glimpses</i> provides instructions for a 3,000 year old Vedic
              meditation technique from India based on original Sanskrit mantras
              from that time. This meditation has now been used and refined by
              yogis for thousands of years and is easy to learn. <i>Glimpses</i>{" "}
              provides a simple meditation practice for beginners that can be
              expanded into deeper and deeper practice over time. Through
              regular meditation you become more familiar with the silence and
              stillness inside of you, which in turn can increase your sense of
              well-being and make you more present. Some call those effects from
              meditation enlightenment, moksha, liberation, self-realization,
              awakening or nonduality.
            </p>

            <p>
              <i>Glimpses</i> is about overcoming the fear of death. Throughout
              history we have managed our fear of death with religious beliefs.
              Recently however research with terminal cancer patients at Johns
              Hopkins University found that psychedelic experiences can greatly
              diminish and even overcome the fear of death. In our experience,
              psychedelics, when administered professionally, have the ability
              to establish enduring inner peace and well-being, and the
              potential to end holy war.{" "}
            </p>

            <p style={{ marginBottom: 0 }}>
              What readers have said about the work of Inner Peace Fellowship:
            </p>
            <Quotes />
            <TableOfContents />
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
